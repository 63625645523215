import { useState } from "react";

import { Badge, Box, Stack } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import SelectOption from "shared/components/navbar/NavbarLink";
import { useUnreadRequests } from "shared/providers/UnreadRequestsProvider";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useAuth } from "../../providers/AuthProvider";
import { useFleets } from "../../providers/FleetProvider";
import AddTeamModal from "../modals/AddTeamModal";

import AccountProfile from "./AccountProfile";
import AccountSwitcher from "./AccountSwitcher";

const InsuranceTabs = ({
  currentPath,
  serviceupUser,
}: {
  currentPath: string;
  serviceupUser: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    avatarUrl: string | null;
  };
}) => {
  const { switchTeamsFlag } = useFlags();
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const router = useRouter();
  const { fleets } = useFleets();
  const { logout } = useAuth();
  const onAddTeamSubmit = (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddTeamModal(false);
    router.push({
      pathname: "/settings",
      query: { fleetId: fleet.id },
    });
  };

  const { unreadRequestsData } = useUnreadRequests();

  const tabs = [
    { path: "/request", label: "Claims" },
    { path: "/history", label: "History" },
    { path: "/settings", label: "Settings" },
  ];

  return (
    <>
      <Stack
        display={{ xs: "none", lg: "flex" }}
        spacing={5}
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Stack>
          <Badge
            badgeContent={unreadRequestsData.length?.toString()}
            max={99}
            invisible={!unreadRequestsData.length}
            sx={{
              "& .MuiBadge-badge": {
                color: "white",
                backgroundColor: "error.main",
              },
            }}
          />
        </Stack>
        <Stack gap={3} direction={"row"} alignItems="center">
          {tabs.map(({ path, label }) => {
            const params = router.asPath.split("?")[1];
            return (
              <SelectOption
                key={path}
                currentPath={currentPath}
                href={`${path}?${params}`}
                text={label}
              />
            );
          })}
        </Stack>
        <Box flexGrow={1} display={"flex"} justifyContent={"flex-end"}>
          {switchTeamsFlag && !!fleets?.length && (
            <AccountSwitcher
              label="Team"
              fleets={fleets.filter((fleet) => fleet.isInsuranceFleet)}
              setShowAddFleetModal={setShowAddTeamModal}
            />
          )}
          <AccountProfile serviceupUser={serviceupUser} logout={logout} />
        </Box>
      </Stack>

      <AddTeamModal
        open={showAddTeamModal}
        onClose={() => setShowAddTeamModal(false)}
        onAddTeamSuccess={onAddTeamSubmit}
      />
    </>
  );
};

export default InsuranceTabs;
