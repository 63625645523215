import { ReactNode } from "react";

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import ModalHeader from "./ModalHeader";

export interface GenericModalProps {
  open: boolean;
  handleClose: () => void;
  onSubmit?: () => Promise<void>;
  title: string;
  subtitle?: string;
  headerIcon: ReactNode;
  content: ReactNode;
  modalActions?: JSX.Element;
  secondaryModalAction?: JSX.Element[];
  disableBackdropClick?: boolean;
  maxWidth?: "sm" | "md";
}

const GenericModal = ({
  title,
  subtitle,
  headerIcon,
  open,
  content,
  handleClose,
  onSubmit,
  modalActions,
  secondaryModalAction,
  disableBackdropClick = false,
  maxWidth = "md",
}: GenericModalProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClosing = (event: Record<string, never>, reason: string) => {
    if (
      disableBackdropClick &&
      (reason === "backdropClick" || reason === "escapeKeyDown")
    ) {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClosing}
      fullWidth
      sx={{ zIndex: 1000 }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      PaperProps={{
        sx: { p: 1 },
        ...(onSubmit && {
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            onSubmit();
          },
        }),
      }}
    >
      <DialogTitle>
        <ModalHeader
          title={title}
          subtitle={subtitle}
          icon={headerIcon}
          onClose={handleClose}
        />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <Box
        display={"flex"}
        justifyContent={secondaryModalAction ? "space-between" : "flex-end"}
      >
        {!!secondaryModalAction && (
          <DialogActions>{secondaryModalAction}</DialogActions>
        )}
        {!!modalActions && <DialogActions>{modalActions}</DialogActions>}
      </Box>
    </Dialog>
  );
};

export default GenericModal;
