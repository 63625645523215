import { AccountSettingsProvider } from "../../providers/AccountSettings/AccountSettingsProvider";
import { FleetProvider } from "../../providers/FleetProvider";
import { ServicesFlowProvider } from "../../providers/ServiceFlowProvider";
import { TagsProvider } from "../../providers/TagsProvider";
import { VehicleProvider } from "../../providers/VehicleProvider";
import { VehicleUploadStatusProvider } from "../../providers/VehicleUploadStatusProvider";
import Navbar from "../navbar/Navbar";

const Layout: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <AccountSettingsProvider>
      <FleetProvider>
        <TagsProvider>
          <ServicesFlowProvider>
            <VehicleProvider>
              <VehicleUploadStatusProvider>
                <Navbar />
                <main>{children}</main>
              </VehicleUploadStatusProvider>
            </VehicleProvider>
          </ServicesFlowProvider>
        </TagsProvider>
      </FleetProvider>
    </AccountSettingsProvider>
  );
};

export default Layout;
