import React, { useContext, useEffect, useState } from "react";

import { signInWithCustomToken, User } from "firebase/auth";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import { auth } from "shared/firebaseConfig/firebase";

const AuthContext = React.createContext<{
  currentUser: User | null;
  assignSessionToken: (token: string) => Promise<void>;
  logout: () => Promise<void>;
}>({
  currentUser: {} as User,
  assignSessionToken: async (_token: string) => void 0,
  logout: async () => void 0,
});

export const errorCodes: Record<string, string> = {
  "auth/user-not-found": "User not found",
  "auth/wrong-password": "Incorrect password",
  "auth/invalid-email": "Invalid email",
  "auth/email-already-in-use": "Email already in use",
  "auth/weak-password": "Password is too weak",
  "auth/too-many-requests": "Too many requests",
  "auth/user-disabled": "User disabled",
  "auth/requires-recent-login": "Requires recent login",
  "auth/user-token-expired": "User token expired",
  "auth/web-storage-unsupported": "Web storage unsupported",
  "auth/invalid-credential": "Invalid credential",
  "auth/operation-not-allowed": "Operation not allowed",
  "auth/unauthorized-domain": "Unauthorized domain",
  "auth/credential-already-in-use": "Credential already in use",
  "auth/popup-blocked": "Popup blocked",
  "auth/popup-closed-by-user": "Popup closed by user",
  "auth/unauthorized-continue-uri": "Unauthorized continue uri",
  "auth/invalid-custom-token": "Invalid custom token",
  "auth/invalid-verification-code": "Invalid verification code",
  "auth/invalid-verification-id": "Invalid verification ID",
  "auth/missing-verification-code": "Missing verification code",
  "auth/invalid-continue-uri": "Invalid continue uri",
  "auth/invalid-dynamic-link-domain": "Invalid dynamic link domain",
  "auth/app-not-authorized": "App not authorized",
  "auth/expired-action-code": "Expired action code",
  "auth/cancelled-popup-request": "Cancelled popup request",
  "auth/internal-error": "Internal error",
} as const;

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
  routeRequiresAuth,
}: {
  children: React.ReactNode;
  routeRequiresAuth: boolean;
}) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const router = useRouter();
  const ldClient = useLDClient();

  async function assignSessionToken(token: string) {
    const credential = await signInWithCustomToken(auth, token);
    setCurrentUser(credential.user);
  }

  async function logout() {
    console.debug("Logging user out from firebase");
    await auth.signOut();
    console.debug("User logged out from firebase");
  }

  useEffect(() => {
    return auth.onAuthStateChanged((user) => {
      const { uid, email } = user || {};
      console.debug("User logged in:", uid, email);
      setCurrentUser(user);
      if (user) {
        ldClient?.identify({
          key: uid,
          email: email ?? undefined,
          name: user.displayName ?? undefined,
        });
      }

      if (routeRequiresAuth && !user) {
        let path = "/login";
        if (router.asPath !== "/") {
          path += `?redirectTo=${
            document.location.pathname + document.location.search
          }`;
        }

        console.info("User isn't logged in, redirecting", path);
        router.push(path);
      }
    });
  }, [routeRequiresAuth, router]);

  const value = {
    currentUser,
    assignSessionToken,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!routeRequiresAuth || currentUser ? children : null}
    </AuthContext.Provider>
  );
}
