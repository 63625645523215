import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Modal,
  ModalProps,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-hot-toast";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import { createFleet } from "../../services/user.service";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "max-content",
  bgcolor: "background.paper",
  borderRadius: 1,
  boxShadow: 24,
  p: 4,
};

interface AddFleetModalTemplateProps extends Omit<ModalProps, "children"> {
  onAddFleetSuccess: (fleet: Fleet) => void;
}

const AddFleetModal = (props: AddFleetModalTemplateProps) => {
  const { onAddFleetSuccess, ...modalProps } = props;

  const [fleetName, setFleetName] = useState("");
  const { currentFleets } = useFleets();
  const [creatingFleet, setCreatingFleet] = useState(false);

  const handleCreate = async () => {
    if (!currentFleets) {
      toast.error(
        "Creating an additional fleet requires that you be in a fleet."
      );
      return;
    }
    // Company || name applies for fleets that already exist with no company name, it uses the fleet name instead as the default
    setCreatingFleet(true);
    const fleet = await createFleet(
      currentFleets[0].id,
      fleetName,
      currentFleets[0].company || currentFleets[0].name
    );
    setCreatingFleet(false);

    if ("message" in fleet) {
      const { message } = fleet;
      if (message) toast.error(message as string);
      return;
    }

    onAddFleetSuccess(fleet);
  };

  return (
    <Modal {...modalProps} onSubmit={handleCreate}>
      <Box sx={style}>
        <Box px={5} pb={5}>
          <Box mt={2}>
            <Typography variant="h6">Create Fleet</Typography>
          </Box>
          <Box mt={1}>
            <Typography variant="body2" noWrap={false}>
              Create additional fleets to separate your vehicles into groups
              <br />
              for better organization. To get started, enter a name for your
              fleet.
            </Typography>
          </Box>
          <Box mt={1}>
            <TextField
              data-testid={"addFleetNameField"}
              autoFocus={true}
              value={fleetName}
              onChange={(e) => setFleetName(e.target.value)}
              id="input-fleetName"
              label={"Enter Fleet Name"}
              fullWidth
            />
          </Box>
          <Box mt={4} display={"flex"} justifyContent={"space-between"}>
            <Button
              variant={"outlined"}
              onClick={() => props.onClose?.({}, "escapeKeyDown")}
            >
              Cancel
            </Button>
            <LoadingButton
              data-testid={"addFleetCreateButton"}
              variant="contained"
              onClick={handleCreate}
              disabled={!fleetName}
              loading={creatingFleet}
            >
              Create Fleet
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddFleetModal;
