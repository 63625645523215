import React, { useEffect, useState } from "react";

import { TextField } from "@mui/material";
import toast from "react-hot-toast";

import FormModal from "shared/components/modals/FormModal";
import { useGetFleetLazyQuery } from "shared/generated/graphql";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useFleets } from "../../providers/FleetProvider";
import { createFleet } from "../../services/user.service";

const AddTeamModal = (props: {
  open: boolean;
  onClose: () => void;
  onAddTeamSuccess: (fleet: Fleet) => void;
}) => {
  const [teamName, setTeamName] = useState<string>("");
  const { currentFleetIdAsNumber, isValidFleetId, refetch } = useFleets();
  const [creatingTeam, setCreatingTeam] = useState<boolean>(false);
  const [getFleet] = useGetFleetLazyQuery();
  const [company, setCompany] = useState<string>();
  const [name, setName] = useState<string>();

  useEffect(() => {
    async function loadFleetData(fleetId: number) {
      if (!isValidFleetId) return;
      const { data, error } = await getFleet({
        variables: {
          fleetId,
        },
      });

      if (data) {
        setName(data.fleets_by_pk?.name);
        if (data.fleets_by_pk?.company) {
          setCompany(data.fleets_by_pk.company);
        }
      } else if (error) {
        toast.error(error.message);
      }
    }

    loadFleetData(currentFleetIdAsNumber);
  }, [currentFleetIdAsNumber, isValidFleetId, getFleet]);
  if (!isValidFleetId) return;

  const handleCreate = async () => {
    // Company || name applies for fleets that already exist with no company name, it uses the fleet name instead as the default
    setCreatingTeam(true);
    const team = await createFleet(
      currentFleetIdAsNumber,
      teamName,
      company || name,
      true
    );
    setCreatingTeam(false);
    if ("message" in team) {
      const { message } = team;
      if (message) toast.error(message as string);
      return;
    }
    await refetch();
    props.onAddTeamSuccess(team);
  };

  return (
    <FormModal
      open={props.open}
      title="CREATE TEAM"
      subtitle="Create additional teams to separate your claims into groups
    for better organization. To get started, enter a name for your
    team."
      onSubmit={handleCreate}
      icon={<React.Fragment />}
      handleClose={props.onClose}
      loading={creatingTeam}
      isValid={!!teamName}
      submitButtonText="Create Team"
      content={
        <TextField
          data-testid={"addTeamNameField"}
          autoFocus={true}
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          id="input-teamName"
          label={"Enter Team Name"}
          fullWidth
        />
      }
    />
  );
};

export default AddTeamModal;
