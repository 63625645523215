/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { strict as assert } from "assert";

// eslint-disable-next-line no-restricted-imports
import moment from "moment";

import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "shared/lib/localStorage";

import {
  CreatedServiceDto,
  CreateRequestDto,
  FixedPriceFilter,
  FixedPriceServices,
  ServiceImageUrl,
  VariablePriceServices,
} from "../../../../backend/src/requests/request.interfaces";
import { ServicesVehicleType } from "../pages/services";
import { calculateServices } from "../services/request.service";

import { useFleets } from "./FleetProvider";

interface ScheduleInfo {
  address: string;
  latLng: any;
  dateStartTime: Date;
  dateEndTime: Date;
  zipcode: string;
}

export interface ImageType {
  uri: string;
  name: string;
  blob: Blob;
}

const initServiceDetails = {
  description: "",
  insuranceCompany: "",
  claimId: "",
};

interface InsuranceInfo {
  insuranceCompany: string;
  claimId: string;
}

export interface ServiceImage extends ServiceImageUrl {
  uri?: string;
  name?: string;
  blob?: Blob;
}

export interface ExtendedCreateRequestDto
  extends Omit<CreateRequestDto, "images"> {
  images?: ServiceImage[];
}

export interface ServicesDataType extends Omit<CreateRequestDto, "images"> {
  images?: ImageType[];
}

export interface FixedPriceServiceInfo {
  primaryHeader: string;
  options: FixedPriceFilter[];
  tooltip?: {
    clickText: string;
    header: string;
    text: JSX.Element;
  };
  insurance?: FixedPriceServiceInfo;
  towing?: FixedPriceServiceInfo;
}

export type ServiceFlowStatus =
  | "INITIAL"
  | "SELECTOR"
  | "PRE_ESTIMATE"
  | "SERVICE_MANAGER_CONSULTATION"
  | "SELECTED_SERVICES"
  | "SELECT_SHOP";

export type ServicesData = {
  [key: FixedPriceServices | VariablePriceServices]: {
    checked: boolean;
    data: Omit<ServicesDataType, "serviceCategory">;
  };
};

export type SelectedServicesForm = ServicesData & {
  mileage?: string;
};

const ServiceFlowContext = createContext<{
  services: CreateRequestDto[];
  approvedServices: CreatedServiceDto[];
  saveApprovedServices: (services: CreatedServiceDto[]) => void;
  serviceFlowStatus: ServiceFlowStatus;
  images: {
    uri: string;
    name: string;
    blob: Blob;
  }[];
  scheduledInfo: ScheduleInfo;
  saveScheduleInfo: (scheduleInfo: ScheduleInfo) => void;
  tow: boolean;
  insuranceInfo: InsuranceInfo | null;
  saveTow: (tow: boolean) => void;
  saveInsuranceInfo: (insuranceInfo: InsuranceInfo) => void;
  changeStatus: (status: ServiceFlowStatus) => void;
  updateService: (
    addService: ExtendedCreateRequestDto[],
    removeService?: ExtendedCreateRequestDto[] | null
  ) => void;
  currentVehicle:
    | {
        id: number;
        imageUrl?: string;
        customImageUrl?: string;
        mileage?: number;
      }
    | ServicesVehicleType
    | undefined;
  setCurrentVehicle: Dispatch<
    | {
        id: number;
        imageUrl?: string;
        customImageUrl?: string;
        mileage?: number;
      }
    | ServicesVehicleType
  >;
  cleanService: (services: CreateRequestDto[]) => void;
  validCategories: string[] | null;
  addOnCategories: string[] | null;
  saveImages: (
    newImages: {
      uri: string;
      name: string;
      blob: Blob;
    }[]
  ) => void;
  getCalculatedServices: () => Promise<{
    nextStatus: "new" | "awaitingShopAssignment";
    services: CreatedServiceDto[];
    addOns: CreatedServiceDto[];
  } | null>;
  clearServices: () => void;
  selectedShopId: number | null | undefined;
  setSelectedShopId: Dispatch<number | null>;
  servicesData: ServicesData;
  setServicesData: Dispatch<ServicesData>;
}>({
  services: [],
  approvedServices: [],
  saveApprovedServices: (_services: CreatedServiceDto[]) => void 0,
  serviceFlowStatus: "SELECTOR",
  images: [],
  scheduledInfo: {
    address: "",
    latLng: null,
    dateStartTime: moment(new Date()).add(1.5, "h").toDate(),
    dateEndTime: moment(new Date()).add(2.5, "h").toDate(),
    zipcode: "",
  },
  saveScheduleInfo: (_scheduleInfo: ScheduleInfo) => void 0,
  tow: false,
  insuranceInfo: {
    insuranceCompany: "",
    claimId: "",
  },
  saveTow: (_tow: boolean) => void 0,
  saveInsuranceInfo: (_insuranceInfo: InsuranceInfo) => void 0,
  changeStatus: (_status: ServiceFlowStatus) => void 0,
  updateService: (_addService: ExtendedCreateRequestDto[]) => void 0,
  cleanService: (_services: CreateRequestDto[]) => void 0,
  validCategories: null,
  addOnCategories: null,
  saveImages: (_newImages: ImageType[]) => void 0,
  getCalculatedServices: async () => null,
  currentVehicle: undefined,
  setCurrentVehicle: null as unknown as Dispatch<
    | ServicesVehicleType
    | { id: number; imageUrl?: string; customImageUrl?: string }
  >,
  clearServices: () => void 0,
  selectedShopId: null,
  setSelectedShopId: null as unknown as Dispatch<number | null>,
  servicesData: {},
  setServicesData: null as unknown as Dispatch<ServicesData>,
});

export function useServices() {
  return useContext(ServiceFlowContext);
}

export function useVehicles() {
  const context = useContext(ServiceFlowContext);

  const { currentVehicle, setCurrentVehicle } = context;

  return { currentVehicle, setCurrentVehicle };
}

const CATEGORIES = [
  "AUTOBODY_DAMAGE",
  "GLASS",
  "PAINTLESS_DENT_REPAIR",
  "OIL_CHANGE",
  "GENERAL_DIAGNOSTICS",
  "BRAKE_PADS",
  "BELTS",
  "MUFFLERS",
  "TRANSMISSION",
  "AC_RECHARGE",
  "BATTERY",
  "ENGINE_REPAIR",
  "SUSPENSION",
  "WHEEL_ALIGNMENT",
  "TIRES",
  "SERVICE_MANAGER_CONSULTATION",
];

export function ServicesFlowProvider({ children }: { children: ReactNode }) {
  const [services, setServices] = useState<CreateRequestDto[]>([]);
  const [selectedShopId, setSelectedShopId] = useState<
    number | null | undefined
  >();
  const [currentVehicle, setCurrentVehicle] = useState<
    | ServicesVehicleType
    | { id: number; imageUrl?: string; customImageUrl?: string }
  >();
  const [approvedServices, setApprovedServices] = useState<CreatedServiceDto[]>(
    []
  );
  const [images, setImages] = useState<ImageType[]>([]);
  const [scheduledInfo, setScheduledInfo] = useState<ScheduleInfo>({
    address: "",
    latLng: null,
    dateStartTime: moment(new Date()).add(1.5, "h").toDate(),
    dateEndTime: moment(new Date()).add(2.5, "h").toDate(),
    zipcode: "",
  });
  const [tow, setTow] = useState(false);
  const [insuranceInfo, setInsuranceInfo] = useState<InsuranceInfo | null>(
    null
  );
  const [serviceFlowStatus, setServiceFlowStatus] =
    useState<ServiceFlowStatus>("INITIAL");
  const [validCategories, setValidCategories] = useState<string[] | null>(
    CATEGORIES
  );
  const [servicesData, setServicesData] = useState<ServicesData>({});
  const [addOnCategories, setAddOnCategories] = useState<string[] | null>(null);
  const { currentFleetIdAsNumber, isValidFleetId } = useFleets();

  const initValidCategories = useCallback(async () => {
    setServicesData(
      CATEGORIES.reduce((stateObj: ServicesData, category: string) => {
        stateObj[category] = {
          checked: false,
          data: {
            details: {
              description: "",
              insuranceCompany: "",
              claimId: "",
            },
            images: [],
            filter: undefined,
          },
        };
        return stateObj;
      }, {})
    );
  }, []);

  useEffect(() => {
    if (!currentVehicle) return;
    initValidCategories();
  }, [currentVehicle?.id]);

  const saveServices = (services: CreateRequestDto[]) => {
    saveToLocalStorage("services", services);
    setServices(services);
  };

  const saveApprovedServices = (services: CreatedServiceDto[]) => {
    saveToLocalStorage("approvedServices", services);
    setApprovedServices(services);
  };

  const saveScheduleInfo = (newScheduleInfo: ScheduleInfo) => {
    saveToLocalStorage("scheduledInfo", newScheduleInfo);
    setScheduledInfo(newScheduleInfo);
  };

  const saveTow = (updatedTow: boolean) => {
    saveToLocalStorage("tow", updatedTow);
    setTow(updatedTow);
  };

  const saveInsuranceInfo = (updatedInsuranceInfo: InsuranceInfo | null) => {
    saveToLocalStorage("insuranceInfo", updatedInsuranceInfo);
    setInsuranceInfo(updatedInsuranceInfo);
  };

  const saveImages = (newImages: ImageType[]) => {
    saveToLocalStorage("images", newImages);
    setImages(newImages);
  };

  const changeStatus = (status: ServiceFlowStatus) => {
    saveToLocalStorage("serviceFlowStatus", status);
    setServiceFlowStatus(status);
    // scroll back to the top of the page
    window.scrollTo(0, 0);
  };

  const updateService = (
    addService: ExtendedCreateRequestDto[],
    removeService?: ExtendedCreateRequestDto[] | null
  ) => {
    const servicesCopy = removeService
      ? removedService(removeService)
      : [...services];
    addService.forEach((service) => {
      if (
        !["BRAKE_PADS", "SUSPENSION", "OIL_CHANGE"].includes(
          service.serviceCategory
        )
      ) {
        const serviceIndex = servicesCopy.findIndex(
          (s) => s.serviceCategory === service.serviceCategory
        );
        if (serviceIndex >= 0) {
          servicesCopy[serviceIndex] = service;
        } else {
          servicesCopy.push(service);
        }
      } else {
        const serviceIndex = servicesCopy.findIndex(
          (s) =>
            s.serviceCategory === service.serviceCategory &&
            s.filter?.optionName === service.filter?.optionName
        );
        if (serviceIndex >= 0) {
          servicesCopy[serviceIndex] = service;
        } else {
          servicesCopy.push(service);
        }
      }
    });
    saveServices(servicesCopy);
  };

  const cleanService = (services: CreateRequestDto[]) => {
    const servicesCopy = removedService(services);
    saveTow(false);
    saveInsuranceInfo(null);
    saveServices(servicesCopy);
    saveApprovedServices([]);
    changeStatus("SELECTOR");
  };

  const removedService = (removeServices: CreateRequestDto[] | null) => {
    if (!removeServices || !removeServices.length) {
      return services;
    }
    let servicesCopy = [...services];
    removeServices.forEach((rS) => {
      servicesCopy = !["BRAKE_PADS", "SUSPENSION", "OIL_CHANGE"].includes(
        rS.serviceCategory
      )
        ? servicesCopy.filter((s) => s.serviceCategory !== rS.serviceCategory)
        : servicesCopy.filter(
            (s) =>
              !(
                s.serviceCategory === rS.serviceCategory &&
                s.filter?.optionName === rS.filter?.optionName
              )
          );
    });

    return servicesCopy;
  };

  const getCalculatedServices = async () => {
    if (!isValidFleetId) return null;
    assert.ok(currentVehicle, "Vehicle must exist");
    let servicesCopy = [...services];
    if (tow) {
      servicesCopy = [
        ...services,
        {
          serviceCategory: "TOWING_FEE",
          details: initServiceDetails,
        },
      ];
    }
    const addOnServices = addOnCategories
      ? addOnCategories.map((addOn) => {
          return {
            serviceCategory: addOn,
            details: initServiceDetails,
          };
        })
      : [];

    const responseAddOns = await calculateServices(
      currentVehicle.id,
      addOnServices
    );
    const responseServices = await calculateServices(
      currentVehicle?.id,
      servicesCopy,
      undefined,
      currentFleetIdAsNumber
    );

    return {
      nextStatus:
        responseServices.nextStatus !== responseAddOns.nextStatus
          ? "awaitingShopAssignment"
          : responseServices.nextStatus,
      services: responseServices.services,
      addOns: responseAddOns.services,
    };
  };

  const loadContextFromLocalStorage = () => {
    setServices(loadFromLocalStorage("services") ?? services);
    setCurrentVehicle(loadFromLocalStorage("vehicle") ?? currentVehicle);
    setSelectedShopId(loadFromLocalStorage("selectedShopId") ?? selectedShopId);
    setApprovedServices(
      loadFromLocalStorage("approvedServices") ?? approvedServices
    );
    setImages(loadFromLocalStorage("images") ?? images);
    setInsuranceInfo(loadFromLocalStorage("insuranceInfo") ?? insuranceInfo);
    setScheduledInfo(loadFromLocalStorage("scheduledInfo") ?? scheduledInfo);
    setTow(loadFromLocalStorage("tow") ?? tow);
    setServiceFlowStatus(
      loadFromLocalStorage("serviceFlowStatus") ?? "SELECTOR"
    );
    setValidCategories(
      loadFromLocalStorage("validCategories") ?? validCategories
    );
    setServicesData(loadFromLocalStorage("servicesData") ?? servicesData);
    setAddOnCategories(
      loadFromLocalStorage("addOnCategories") ?? addOnCategories
    );
  };

  const clearServices = () => {
    setServices([]);
    setCurrentVehicle(undefined);
    setApprovedServices([]);
    setImages([]);
    setInsuranceInfo(null);
    setScheduledInfo({
      address: "",
      latLng: null,
      dateStartTime: moment(new Date()).add(1.5, "h").toDate(),
      dateEndTime: moment(new Date()).add(2.5, "h").toDate(),
      zipcode: "",
    });
    setTow(false);
    setServiceFlowStatus("SELECTOR");
    setValidCategories(null);
    setServicesData({});
    setAddOnCategories(null);
  };

  useEffect(() => {
    loadContextFromLocalStorage();
  }, []);

  const value = {
    services,
    approvedServices,
    saveApprovedServices,
    serviceFlowStatus,
    images,
    scheduledInfo,
    saveScheduleInfo,
    tow,
    insuranceInfo,
    saveTow,
    saveInsuranceInfo,
    changeStatus,
    updateService,
    cleanService,
    validCategories,
    addOnCategories,
    saveImages,
    getCalculatedServices,
    currentVehicle,
    setCurrentVehicle,
    clearServices,
    selectedShopId,
    setSelectedShopId,
    servicesData,
    setServicesData,
  };

  return (
    <ServiceFlowContext.Provider value={value}>
      {children}
    </ServiceFlowContext.Provider>
  );
}
