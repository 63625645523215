import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "shared/lib/localStorage";

import { Settings, VehicleView, VehicleViewType } from "./vehicleViewUtils";

const DEFAULT_SETTINGS: Settings = {
  view: VehicleView.List,
};

const AccountSettingsContext = createContext<{
  settings: Settings;
  saveView: (view: VehicleViewType) => void;
}>({
  settings: { view: VehicleView.List },
  saveView: (_view: VehicleViewType) => void 0,
});

export function useAccountSettings() {
  return useContext(AccountSettingsContext);
}

const AccountSettingsKey = "accountSettings";

function isValidSettings(settings: unknown): settings is Settings {
  return (
    typeof settings === "object" &&
    !!settings &&
    "view" in settings &&
    typeof settings.view === "string" &&
    ["card", "list"].includes(settings.view)
  );
}

export function AccountSettingsProvider({ children }: { children: ReactNode }) {
  const initialSettings = loadFromLocalStorage(AccountSettingsKey);
  const [settings, setSettings] = useState<Settings>(
    isValidSettings(initialSettings) ? initialSettings : DEFAULT_SETTINGS
  );

  const saveView = useCallback((view: VehicleViewType) => {
    setSettings((prev) => {
      const updated = { ...prev, view };
      saveToLocalStorage(AccountSettingsKey, updated);
      return updated;
    });
  }, []);

  const value = useMemo(() => {
    return {
      settings,
      saveView,
    };
  }, [settings, saveView]);

  return (
    <AccountSettingsContext.Provider value={value}>
      {children}
    </AccountSettingsContext.Provider>
  );
}
