import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ApolloError } from "@apollo/client";
import _ from "lodash";

import {
  Requests_Order_By,
  useGetOpenRequestsLazyQuery,
  useGetUsersFleetVehiclesLazyQuery,
  UsersFleetVehicles_Order_By,
} from "shared/generated/graphql";

import useFleetSearch, {
  FleetSearchModel,
} from "../components/fleets/useFleetSearch";
import {
  VehicleCardType,
  VehicleRowType,
} from "../components/vehicles/tables/types";

import { useFleets } from "./FleetProvider";

type VehicleType = VehicleRowType & VehicleCardType;

type VehicleWithFleetId = {
  vehicleId: number;
  fleetId: number;
};

const VehicleContext = createContext<{
  vehicles: VehicleType[];
  vehiclesWithRequests: VehicleType[];
  count: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  search: string;
  updateFleetSearch: (newSearch: FleetSearchModel) => void;
  selectedVehicles: VehicleWithFleetId[];
  selectVehicle: (data: VehicleWithFleetId) => void;
  unselectVehicle: (vehicleId: number) => void;
  clearSelectedVehicles: () => void;
  selectedFleetTags: number[];
  setSelectedFleetTags: Dispatch<SetStateAction<number[]>>;
  setOrder: Dispatch<SetStateAction<UsersFleetVehicles_Order_By | undefined>>;
  error: ApolloError | undefined;
  loading: boolean;
}>({
  vehicles: [],
  vehiclesWithRequests: [],
  count: 0,
  page: 0,
  setPage: null as unknown as Dispatch<SetStateAction<number>>,
  search: "",
  updateFleetSearch: null as unknown as (newSearch: FleetSearchModel) => void,
  selectedVehicles: [],
  selectVehicle: (_data: VehicleWithFleetId) => void 0,
  unselectVehicle: (_vehicleId: number) => void 0,
  clearSelectedVehicles: () => void 0,
  selectedFleetTags: [],
  setSelectedFleetTags: null as unknown as Dispatch<SetStateAction<number[]>>,
  setOrder: null as unknown as Dispatch<
    SetStateAction<UsersFleetVehicles_Order_By | undefined>
  >,
  error: undefined,
  loading: false,
});

export function useVehicles() {
  return useContext(VehicleContext);
}

export function VehicleProvider({ children }: { children: ReactNode }) {
  const limit = 100;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [vehicles, setVehicles] = useState<VehicleType[]>([]);
  const [vehiclesWithRequests, setVehiclesWithRequests] = useState<
    VehicleType[]
  >([]);
  const { fleetSearch, updateFleetSearch } = useFleetSearch();
  const [selectedVehicles, setSelectedVehicles] = useState<
    VehicleWithFleetId[]
  >([]);
  const [selectedFleetTags, setSelectedFleetTags] = useState<number[]>([]);
  const [order, setOrder] = useState<
    UsersFleetVehicles_Order_By | Requests_Order_By
  >();
  const [error, setError] = useState<ApolloError>();
  const [loading, setLoading] = useState(true);
  const { currentFleetIdAsNumber, currentFleetIds } = useFleets();

  const constructedQuery = useMemo(
    () => ({
      variables: {
        skip: !currentFleetIds,
        orderBy: [{ ...order }],
        filter: {
          fleetId:
            currentFleetIds && currentFleetIds?.length > 1
              ? { _in: currentFleetIds }
              : { _eq: currentFleetIdAsNumber },
          _and: [
            {
              deletedAt: { _is_null: true },
            },
            {
              _or: [
                {
                  carNickname: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  year: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  make: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  model: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  plate: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  vin: { _ilike: `%${fleetSearch.search}%` },
                },
                {
                  requests: {
                    services: {
                      description: { _ilike: `%${fleetSearch.search}%` },
                    },
                  },
                },
              ],
            },
            ...(selectedFleetTags.length > 0
              ? [{ vehicleTags: { tagId: { _in: selectedFleetTags } } }]
              : []),
          ],
        },
        limit: limit,
      },
    }),
    [
      currentFleetIds,
      currentFleetIdAsNumber,
      fleetSearch.search,
      selectedFleetTags,
      order,
    ]
  );

  // Modify query variables if we would like to order by RO #
  const orderByForRequests = useMemo(() => {
    return order?.id ? { ...order } : { usersFleetVehicles: { ...order } };
  }, [order]);

  const requestQuery = useMemo(
    () => ({
      variables: {
        orderBy: orderByForRequests,
        filter: constructedQuery.variables.filter,
        fleetIds: currentFleetIds,
      },
      skip: !currentFleetIds,
    }),
    [constructedQuery, orderByForRequests, currentFleetIds]
  );

  const [getVehicles] = useGetUsersFleetVehiclesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getRequests] = useGetOpenRequestsLazyQuery({
    fetchPolicy: "network-only",
  });

  const fetchVehicles = async (query: typeof constructedQuery) => {
    const { data, error } = await getVehicles(query);

    if (error) setError(error);

    return {
      data: data?.usersFleetVehicles,
      count: data?.usersFleetVehicles_aggregate?.aggregate?.count || 0,
    };
  };

  const selectVehicle = (data: VehicleWithFleetId) => {
    if (!selectedVehicles.find((v) => v.vehicleId === data.vehicleId)) {
      setSelectedVehicles([...selectedVehicles, data]);
    }
  };

  const unselectVehicle = (vehicleId: number) => {
    setSelectedVehicles(
      selectedVehicles.filter((v) => v.vehicleId !== vehicleId)
    );
  };

  const clearSelectedVehicles = () => {
    setSelectedVehicles([]);
  };

  useEffect(() => {
    const run = async () => {
      const vehicleQuery = await fetchVehicles(constructedQuery);
      if (vehicleQuery.data) {
        setVehicles(vehicleQuery.data);
        setCount(vehicleQuery.count);
      }

      const { data } = await getRequests(requestQuery);

      if (data?.requests)
        setVehiclesWithRequests(
          data.requests
            .filter((r) => r.usersFleetVehicles)
            .map((request) => ({
              requests: [{ ...request }],
              ...request.usersFleetVehicles?.vehicle,
              ...request.usersFleetVehicles,
            }))
        );

      setLoading(false);
    };

    setError(undefined);
    setLoading(true);
    setPage(0);
    run();
  }, [getVehicles, constructedQuery]);

  useEffect(() => {
    const nextPage = async () => {
      const nextQuery = { ...constructedQuery.variables, offset: page * limit };

      const { data } = await getVehicles({
        variables: nextQuery,
      });

      if (data?.usersFleetVehicles) {
        setVehicles((prev) => [...prev, ...data.usersFleetVehicles]);
      }
    };

    if (page > 0) nextPage();
  }, [constructedQuery.variables, getVehicles, page]);

  const value = useMemo(() => {
    return {
      count,
      vehicles,
      page,
      setPage,
      search: fleetSearch.search,
      updateFleetSearch,
      selectedVehicles,
      selectVehicle,
      unselectVehicle,
      clearSelectedVehicles,
      selectedFleetTags,
      setSelectedFleetTags,
      setOrder,
      error,
      loading,
      vehiclesWithRequests,
    };
  }, [
    count,
    error,
    fleetSearch.search,
    loading,
    page,
    selectedFleetTags,
    updateFleetSearch,
    vehicles,
    vehiclesWithRequests,
  ]);

  return (
    <VehicleContext.Provider value={value}>{children}</VehicleContext.Provider>
  );
}
