import { Home } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CarRepairIcon from "@mui/icons-material/CarRepair";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Url } from "next/dist/shared/lib/router/router";
import { useRouter } from "next/router";

import { useAuth } from "../../providers/AuthProvider";
import { useFleets } from "../../providers/FleetProvider";

type OptionProps = {
  text: string;
  icon: JSX.Element;
  href?: Url;
  onClick?: () => void;
  disabled?: boolean;
};
const DrawerOption = ({ option }: { option: OptionProps }) => {
  const { href, onClick } = option;
  const router = useRouter();
  const buttonAction = href ? () => router.push(href) : onClick;

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={buttonAction} disabled={option.disabled}>
        <ListItemIcon>{option.icon}</ListItemIcon>
        <ListItemText primary={option.text} />
      </ListItemButton>
    </ListItem>
  );
};
const MobileDrawer = ({
  openDrawer,
  setOpenDrawer,
  isAuthenticated,
}: {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
  isAuthenticated: boolean;
}) => {
  const { logout } = useAuth();
  const { currentFleetIdAsNumber } = useFleets();
  const isSingleFleetId = currentFleetIdAsNumber !== -1;
  const isInsurance = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";
  const router = useRouter();

  const authenticatedOptions = [
    {
      text: isInsurance ? "Claims" : "Orders",
      icon: <CarRepairIcon />,
      href: `/request`,
    },
    {
      text: "History",
      icon: <FileCopyIcon />,
      href: `/history`,
    },
    {
      text: "Vehicles",
      icon: <DirectionsCarFilledIcon />,
      href: `/vehicles`,
    },
    {
      text: "Insights",
      icon: <DashboardIcon />,
      href: `/insights`,
    },
    {
      text: "Shops",
      icon: <StorefrontIcon />,
      href: `/shops`,
      disabled: !isSingleFleetId,
    },
    {
      text: "Settings",
      icon: <SettingsIcon />,
      href: `/settings`,
      disabled: !isSingleFleetId,
    },
    {
      text: "Account",
      icon: <AccountCircleIcon />,
      href: `/settings/account`,
      disabled: !isSingleFleetId,
    },
    {
      text: "Sign Out",
      icon: <LogoutIcon />,
      onClick: () => logout(),
    },
  ];

  const unauthenticatedOptions = [
    {
      text: "Home",
      icon: <Home />,
      href: "/",
    },
    {
      text: "Services",
      icon: <SpaceDashboardOutlinedIcon />,
      href: "/services",
    },
    {
      text: "Help",
      icon: <HelpIcon />,
      href: "/help",
    },
    {
      text: "Sign In",
      icon: <PersonIcon />,
      href: "/login",
    },
    {
      text: "Sign Up",
      icon: <PeopleIcon />,
      href: "/signup",
    },
  ];

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };

  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      className={"box-mobile"}
      onClose={toggleDrawer(false)}
      sx={{
        display: {
          xs: "block",
          lg: "none",
        },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          minWidth: 240,
        },
      }}
    >
      <Box
        width={"250px"}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          {isAuthenticated &&
            authenticatedOptions.map((option) => {
              const params = router.asPath.split("?")[1];
              if (
                isInsurance &&
                ![
                  "Claims",
                  "History",
                  "Users",
                  "Settings",
                  "Account",
                  "Sign Out",
                ].includes(option.text)
              ) {
                return null;
              }
              return (
                <DrawerOption
                  option={{ ...option, href: `${option.href}?${params}` }}
                  key={option.text}
                />
              );
            })}
          {!isAuthenticated &&
            unauthenticatedOptions.map((option) => {
              return <DrawerOption option={option} key={option.text} />;
            })}
        </List>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
