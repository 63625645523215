import { ReactNode } from "react";

import { Box, Container, Stack } from "@mui/material";
import Image from "next/legacy/image";

import { useColorMode } from "../providers/ColorModeProvider";

export function ColumnLayout({
  children,
  showLogo,
}: {
  children: ReactNode;
  showLogo?: boolean;
}) {
  const { color } = useColorMode();
  const initialUrl =
    color === "light"
      ? "/serviceUpMainLogoLight.svg"
      : "/serviceUpMainLogoDark.svg";
  return (
    <Container maxWidth={"xs"}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "100vh" }}
      >
        {showLogo && (
          <Box>
            <Image
              width={121}
              height={77.28}
              objectFit="contain"
              src={initialUrl}
              alt="ServiceUp Logo"
            />
          </Box>
        )}
        {children}
      </Stack>
    </Container>
  );
}
