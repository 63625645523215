import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Box, IconButton, Stack } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import Image from "next/legacy/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { Fleet } from "../../../../../backend/src/fleets/fleet.entity";
import { useAuth } from "../../providers/AuthProvider";
import { useFleets } from "../../providers/FleetProvider";
import AddFleetModal from "../modals/AddFleetModal";

import AccountSwitcher from "./AccountSwitcher";
import DesktopViewAuthenticated from "./DesktopViewAuthenticated";
import MobileDrawer from "./MobileDrawer";

const isInsurancePortal = process.env.NEXT_PUBLIC_PROJECT_NAME === "insurance";

type MobileViewProps = {
  showAddFleetModal: boolean;
  isAuthenticated: boolean;
  setShowAddFleetModal: Dispatch<SetStateAction<boolean>>;
  onAddFleetSubmit: (fleet: Fleet) => void;
};

function MobileView({
  showAddFleetModal,
  setShowAddFleetModal,
  onAddFleetSubmit,
  isAuthenticated,
}: MobileViewProps) {
  const [open, setOpen] = useState(false);
  const { fleets } = useFleets();

  return (
    <Stack
      width={"100%"}
      display={{ xs: "flex", lg: "none" }}
      gap={2}
      justifyContent={"space-between"}
      direction={"row"}
      alignItems={"center"}
    >
      <IconButton>
        <Image
          src="/apple-touch-icon.png"
          alt="ServiceUp Logo"
          height={40}
          width={40}
        />
      </IconButton>
      <Box
        display={"flex"}
        flexGrow={{ md: 1, xs: 0.5 }}
        gap={2}
        justifyContent={{ md: "flex-end", xs: "space-between" }}
      >
        {!!fleets?.length && (
          <AccountSwitcher
            label={isInsurancePortal ? "Team" : "Fleet"}
            fleets={
              isInsurancePortal
                ? fleets.filter((fleet) => fleet.isInsuranceFleet)
                : fleets
            }
            setShowAddFleetModal={setShowAddFleetModal}
          />
        )}
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          disableFocusRipple
          disableRipple
          sx={{ color: "#000000" }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <GridMenuIcon />
        </IconButton>
      </Box>
      <AddFleetModal
        open={showAddFleetModal}
        onClose={() => setShowAddFleetModal(false)}
        onAddFleetSuccess={onAddFleetSubmit}
      />
      <MobileDrawer
        isAuthenticated={isAuthenticated}
        openDrawer={open}
        setOpenDrawer={setOpen}
      />
    </Stack>
  );
}

export default function Navbar() {
  const router = useRouter();
  const { currentUser, logout } = useAuth();
  const [showAddFleetModal, setShowAddFleetModal] = useState(false);
  const {
    currentFleets,
    currentFleetId: fleetId,
    setCurrentFleetId,
    refetch,
  } = useFleets();

  const onAddFleetSubmit = async (fleet: Fleet) => {
    if (!fleet) return;
    setShowAddFleetModal(false);
    setCurrentFleetId(fleet.id, "/request");
    await refetch();
  };

  useEffect(() => {
    const checkFleetAndLogout = async () => {
      const isInsuranceFleet = currentFleets?.some(
        (fleet) => fleet.isInsuranceFleet
      );
      if (!isInsuranceFleet) return;

      if (isInsurancePortal !== isInsuranceFleet) {
        await logout();
        if (!process.env.NEXT_PUBLIC_PROJECT_URL) return;
        window.location.href = process.env.NEXT_PUBLIC_PROJECT_URL;
      }
    };

    checkFleetAndLogout();
  }, [currentFleets, logout, router]);

  return (
    <Box py={1.5} px={2} bgcolor={"background.default"}>
      <Stack
        gap={3}
        display="flex"
        justifyContent={{ xs: "space-between" }}
        alignItems="center"
        flexDirection="row"
      >
        <Box
          display={{ xs: "none", lg: "flex" }}
          alignItems="center"
          width={{ lg: "max-content" }}
        >
          <Box display={{ xs: "none", lg: "block" }}>
            <Link href={`/request?fleetId=${fleetId}`}>
              <Image
                src="/images/ServiceUp.svg"
                alt="ServiceUp Logo"
                layout="fixed"
                objectFit="contain"
                height={40}
                width={190}
              />
            </Link>
          </Box>
        </Box>
        <Box display="flex" width={"100%"} gap={{ xs: 2, lg: 0 }}>
          <MobileView
            isAuthenticated={currentUser !== null}
            showAddFleetModal={showAddFleetModal}
            setShowAddFleetModal={setShowAddFleetModal}
            onAddFleetSubmit={onAddFleetSubmit}
          />
          <DesktopViewAuthenticated currentPath={router.asPath} />
        </Box>
      </Stack>
    </Box>
  );
}
