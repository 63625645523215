import React, { useState } from "react";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import LogoutIcon from "@mui/icons-material/Logout";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import {
  Avatar,
  Box,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useRouter } from "next/router";

import ServiceUpSwitch from "shared/components/input/ServiceupSwitch";
import AskQuestion from "shared/components/modals/AskQuestion";
import { useColorMode } from "shared/providers/ColorModeProvider";

import { FromWebsite } from "../../../../../backend/common/customerSupport.types";
import { getDisplayName } from "../../../../../backend/common/utils/user.utils";
import { useFleets } from "../../providers/FleetProvider";

export default function AccountProfile({
  logout,
  serviceupUser,
}: {
  logout: () => void;
  serviceupUser: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    avatarUrl: string | null;
  };
}) {
  const { currentFleetIdAsNumber, isValidFleetId } = useFleets();
  const { darkBrandon } = useFlags();
  const { color, toggleColorMode } = useColorMode();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const router = useRouter();
  const open = Boolean(anchorEl);
  const [showAskQuestionModal, setShowAskQuestionModal] = useState(false);
  const handleClickDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box>
      <Tooltip
        title={`${getDisplayName(serviceupUser)} | ${serviceupUser.email}`}
      >
        <Avatar
          alt={getDisplayName(serviceupUser)}
          src={serviceupUser.avatarUrl ?? undefined}
          onClick={handleClickDropdown}
          sx={{ cursor: "pointer" }}
        />
      </Tooltip>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
        }}
      >
        <Tooltip
          title={!isValidFleetId ? "Coming soon to All Fleets view." : ""}
        >
          <span>
            <MenuItem
              disabled={!isValidFleetId}
              onClick={() =>
                router.push({
                  pathname: "/settings/account",
                  query: { fleetId: currentFleetIdAsNumber },
                })
              }
            >
              <ListItemIcon>
                <Avatar
                  alt={getDisplayName(serviceupUser)}
                  src={serviceupUser.avatarUrl ?? undefined}
                  sx={{ width: 24, height: 24 }}
                />
              </ListItemIcon>
              <ListItemText primary={"Profile"} />
            </MenuItem>
          </span>
        </Tooltip>
        <MenuItem onClick={() => setShowAskQuestionModal(true)}>
          <ListItemIcon>
            <QuestionAnswerOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={"Get Help"} />
        </MenuItem>
        {darkBrandon && (
          <MenuItem onClick={toggleColorMode}>
            <ListItemIcon>
              <DarkModeIcon />
            </ListItemIcon>
            <ListItemText primary={"Dark Mode"} />
            <ServiceUpSwitch
              edge="end"
              checked={color === "dark"}
              // No need to implement this, the click is really handled by the menu item
              onChange={() => void 0}
              size="small"
              sx={{ ml: 3 }}
            />
          </MenuItem>
        )}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Sign Out"} />
        </MenuItem>
      </Menu>
      {showAskQuestionModal && (
        <AskQuestion
          onClose={() => setShowAskQuestionModal(false)}
          from={FromWebsite.FleetPortal}
        />
      )}
    </Box>
  );
}
