import { ChipProps } from "@mui/material";
import { toast } from "react-hot-toast";

import { getAuthenticatedRequestHeaders } from "shared/services/serviceUtils";

import { PromoCode } from "../../../../backend/common/promoCode.entity";
import { RequestState } from "../../../../backend/common/states";
import { CreateRequestDto } from "../../../../backend/src/requests/request.interfaces";

const ACTION_REQUESTED_STATII = new Set([
  "awaitingApproval",
  "approved",
  "awaitingPayment",
  "invoiceApproved",
  "paid",
  "inShopAwaitingApproval",
]);
const ASK_FOR_INVOICE_STATII = new Set([
  "awaitingPayment",
  "paid",
  "scheduledDropoff",
  "deliveryInProgress",
  "closed",
]);

export const isRequestPendingAction = (status: string): boolean => {
  return ACTION_REQUESTED_STATII.has(status);
};

export const isRequestInvoice = (status: string): boolean => {
  return ASK_FOR_INVOICE_STATII.has(status);
};

export const RequestStatusDisplayVehicleText = new Map([
  ["new", "Service Pending"],
  ["assigned", "Service Pending"],
  ["awaitingApproval", "View Estimate"],
  ["inShopAwaitingApproval", "View Estimate"],
  ["approved", "Schedule Pick Up"],
  ["awaitingShopAcceptance", "Pick Up Scheduled"],
  ["awaitingShopAssignment", "Pick Up Scheduled"],
  ["scheduledPickup", "Pick Up Scheduled"],
  ["inShop", "In Shop"],
  ["inProgress", "In Progress"],
  ["awaitingPayment", "View Invoice"],
  ["paid", "Schedule Drop Off"],
  ["invoiceApproved", "Schedule Drop Off"],
  ["scheduledDropoff", "Drop Off Scheduled"],
  ["deliveryInProgress", "On Its Way"],
]);

export const SaasRequestStatusDisplayVehicleText = new Map([
  ["new", "Service Pending"],
  ["awaitingShopAssignment", "Select New Shop"],
  ["awaitingShopAcceptance", "Service Pending"],
  ["assigned", "Service Pending"],
  ["awaitingApproval", "View Estimate"],
  ["inShopAwaitingApproval", "View Estimate"],
  ["approved", "Schedule Drop Off"],
  ["scheduledPickup", "Drop Off Scheduled"],
  ["inShop", "In Shop"],
  ["inProgress", "In Progress"],
  ["awaitingPayment", "View Invoice"],
  ["paid", "Schedule Pick Up"],
  ["invoiceApproved", "Schedule Pick Up"],
  ["scheduledDropoff", "Pick Up Scheduled"],
  ["deliveryInProgress", "On Its Way"],
]);

export const requestStatusColorMap = new Map<
  RequestState | string | null | undefined,
  ChipProps["color"]
>([
  [RequestState.AWAITING_PAYMENT, "warning"],
  [undefined, "default"],
  [null, "default"],
]);

export async function getPromoCodeData(
  code: string
): Promise<{ data: PromoCode } | undefined> {
  try {
    const requestOptions = {
      method: "GET",
    };
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/request/promoCode/${code}`,
      requestOptions
    );
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.message);
    }
    return await response.json();
  } catch (error) {
    console.error(`There was an error fetching promo code: ${error}`);
    toast.error("There was an error fetching promo code.");
  }
}

export async function sendEstimateEmail(userId: number, requestId: number) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/request/${requestId}/user/${userId}/email/estimate`,
      requestOptions
    );
  } catch (error) {
    console.error("Could not send estimate email", error);
    toast.error("There was an error sending the estimate.");
  }
}

export async function quickpayRequest(
  userId: number,
  requestId: number,
  token: any,
  amount: any
) {
  try {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ token, amount }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/payment/${userId}/requests/${requestId}/pay`,
      requestOptions
    );
  } catch (error) {
    console.error(error);
    toast.error("There was an error making your payment.");
  }
}

export async function calculateServices(
  vehicleId: number,
  requests: CreateRequestDto[],
  discountCode?: string,
  fleetId?: number | null
) {
  try {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ vehicleId, requests, discountCode, fleetId }),
    };
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/request/calculate`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error("There was an error getting the service options");
  }
}

export async function createServices(
  vehicleId: number,
  requests: CreateRequestDto[],
  discountCode?: string,
  fleetId?: number | null,
  mileageIn?: number | null,
  skipEstimate?: boolean,
  channel?: string,
  selectedShopId?: number | null
) {
  try {
    const requestOptions = {
      method: "POST",
      headers: await getAuthenticatedRequestHeaders(),
      body: JSON.stringify({
        vehicleId,
        requests,
        discountCode,
        fleetId,
        mileageIn,
        skipEstimate,
        channel,
        shopId: selectedShopId,
      }),
    };
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_APIV2_URL}/request`,
      requestOptions
    );
    return await response.json();
  } catch (error) {
    console.error(error);
    toast.error("There was an error creating your request");
  }
}

export async function applyPromoCodeNoAuth(
  userId: number,
  requestId: number,
  discountCode: string
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ discountCode }),
  };

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APIV2_URL}/request/${requestId}/user/${userId}/applyPromoCode`,
    requestOptions
  );
  if (!response.ok) {
    const errorData = await response.json();
    console.warn("Unable to apply promo code %o", errorData);
    throw new Error("Unable to apply promo code");
  }
  return await response.json();
}
