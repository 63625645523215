import { cloneElement, isValidElement, ReactNode } from "react";

import { IconProps, Stack, SxProps, Typography } from "@mui/material";

import { Colors } from "../../theme/colors";
import CloseIconButton from "../buttons/CloseIconButton";

const ModalHeader = ({
  title,
  subtitle,
  icon,
  onClose,
  sx,
}: {
  title: string;
  subtitle?: string;
  icon: ReactNode;
  onClose: () => void;
  sx?: SxProps;
}) => {
  const styledIcon = isValidElement<IconProps>(icon)
    ? cloneElement(icon, {
        sx: {
          fontSize: 50,
          color: Colors.Yellow,
          mr: "28px",
        },
      })
    : icon;
  return (
    <>
      <Stack direction="row" rowGap={3} mb={2} justifyContent={"space-between"}>
        <Stack sx={sx} direction="row" alignItems="center">
          {styledIcon}
          <Stack>
            <Typography variant="h6">{title}</Typography>
            {!!subtitle && <Typography variant="body1">{subtitle}</Typography>}
          </Stack>
        </Stack>

        <CloseIconButton handleClose={onClose} />
      </Stack>
    </>
  );
};

export default ModalHeader;
