import { RequestHistoryEvent } from "../requestHistory.events";
import { ShopInvoiceStatus } from "../shopInvoice.types";
import { SaaSRequestContext } from "./saas.shop.states";

export const isSaaS = (context: Pick<SaaSRequestContext, "workflowType">) => {
  return context.workflowType === "saas";
};

export const isEstimateApproved = (
  context: Pick<SaaSRequestContext, "services">
) => {
  return !!context.services?.some((service) => service.approved !== null);
};

export const isEstimateSent = (
  context: Pick<SaaSRequestContext, "history">
) => {
  return (
    !!context.history?.find(
      (h) => h.eventType === RequestHistoryEvent.ShopEstimate
    ) ||
    !!context.history?.find((h) => h.changeset && "totalPrice" in h.changeset)
  );
};

export const isInvalidShopECD = (
  context: Pick<SaaSRequestContext, "shopECD">
) => {
  const ecd = context.shopECD ? new Date(context.shopECD) : null;
  if (!ecd) {
    return true;
  }
  const now = new Date();
  return ecd.getTime() < now.getTime();
};

export const isPendingTeardown = (
  context: Pick<SaaSRequestContext, "history">
) => {
  const hasTeardown = context.history?.find(
    (h) => h.eventType === RequestHistoryEvent.Diagnostics
  );
  return !hasTeardown;
};

export const hasServiceWithParts = (
  context: Pick<SaaSRequestContext, "services">
) => {
  return !!context.services?.some((service) =>
    service.serviceItems?.some((item) => item.type === "part")
  );
};

export const hasPartsOrdered = (
  context: Pick<SaaSRequestContext, "history" | "services">
) => {
  const latestPartsOrdered = context.history?.findLast(
    (h) => h.eventType === RequestHistoryEvent.PartsOrdered
  );
  const latestPartsReceived = context.history?.findLast(
    (h) => h.eventType === RequestHistoryEvent.PartsReceived
  );
  const latestPartsOrderedDate = latestPartsOrdered?.createdAt
    ? new Date(latestPartsOrdered?.createdAt)
    : null;
  const latestPartsReceivedDate = latestPartsReceived?.createdAt
    ? new Date(latestPartsReceived?.createdAt)
    : null;
  if (!latestPartsOrderedDate) {
    return false;
  }
  const isReceivedDateBeforeOrderedDate =
    !latestPartsReceivedDate ||
    latestPartsOrderedDate.getTime() > latestPartsReceivedDate.getTime();
  return hasServiceWithParts(context) && isReceivedDateBeforeOrderedDate;
};

export const hasWorkCompleted = (
  context: Pick<SaaSRequestContext, "history">
) => {
  return !!context.history?.find(
    (h) => h.eventType === RequestHistoryEvent.WorkCompleted
  );
};

export const hasShopInvoice = (
  context: Pick<SaaSRequestContext, "history">
) => {
  return !!context.history?.find(
    (h) => h.eventType === RequestHistoryEvent.ShopInvoice
  );
};

export const wasEstimateSkipped = (
  context: Pick<SaaSRequestContext, "wasEstimateSkipped">
) => {
  return !!context.wasEstimateSkipped;
};

export const isCollision = (
  context: Pick<SaaSRequestContext, "requestCategories">
) => {
  return !!context.requestCategories?.some(
    (c) => "category" in c && c.category && c.category === "Autobody Damage"
  );
};

export const needsNewSupplement = (
  context: Pick<SaaSRequestContext, "history">
) => {
  const latestDiagnosticThatNeedsNewSupplement = context.history?.findLast(
    (h) =>
      h.eventType === RequestHistoryEvent.Diagnostics &&
      h.changeset &&
      "additionalWorkRecommended" in h.changeset &&
      h.changeset.additionalWorkRecommended
  );
  const latestNewSupplementEvent = context.history?.findLast(
    (h) => h.eventType === RequestHistoryEvent.NewSupplement
  );
  const latestDiagnosticThatNeedsNewSupplementDate =
    latestDiagnosticThatNeedsNewSupplement?.createdAt
      ? new Date(latestDiagnosticThatNeedsNewSupplement?.createdAt)
      : null;
  const latestNewSupplementEventDate = latestNewSupplementEvent?.createdAt
    ? new Date(latestNewSupplementEvent?.createdAt)
    : null;

  if (
    latestDiagnosticThatNeedsNewSupplementDate &&
    latestNewSupplementEventDate
  ) {
    return (
      latestDiagnosticThatNeedsNewSupplementDate.getTime() >
      latestNewSupplementEventDate.getTime()
    );
  }

  return !!latestDiagnosticThatNeedsNewSupplementDate;
};

export const isWaitingForApprovedNewSupplement = (
  context: Pick<SaaSRequestContext, "history">
) => {
  const latestNewSupplementEvent = context.history?.findLast(
    (h) => h.eventType === RequestHistoryEvent.NewSupplement
  );
  const latestAcceptedNewSupplementEvent = context.history?.findLast(
    (h) =>
      h.eventType === RequestHistoryEvent.AcceptNewSupplement ||
      h.eventType === RequestHistoryEvent.RejectNewSupplement
  );
  const latestNewSupplementEventDate = latestNewSupplementEvent?.createdAt
    ? new Date(latestNewSupplementEvent?.createdAt)
    : null;
  const latestAcceptedNewSupplementEventDate =
    latestAcceptedNewSupplementEvent?.createdAt
      ? new Date(latestAcceptedNewSupplementEvent?.createdAt)
      : null;

  if (latestNewSupplementEventDate && latestAcceptedNewSupplementEventDate) {
    return (
      latestNewSupplementEventDate.getTime() >
      latestAcceptedNewSupplementEventDate.getTime()
    );
  }

  return !!latestNewSupplementEventDate;
};

export const isShopInvoiceProcessed = (
  context: Pick<SaaSRequestContext, "shopInvoices" | "shopId">
) => {
  const shopInvoice = context.shopInvoices?.find(
    (invoice) => invoice.shopId === context.shopId
  );
  return shopInvoice?.status === ShopInvoiceStatus.Processed;
};

export const isRequestCanceled = (
  context: Pick<SaaSRequestContext, "history">
) => {
  const requestCanceledEvent = context.history?.find(
    (h) => h.eventType === RequestHistoryEvent.CancelRequest
  );

  return !!requestCanceledEvent;
};

export const ensureShopInvoiceIsNotProcessed = (context: SaaSRequestContext) =>
  !isShopInvoiceProcessed(context) && !isSaaS(context);
