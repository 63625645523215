import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";

import assert from "assert";

import { useGetFleetVehiclesTagsQuery } from "shared/generated/graphql";

import { useFleets } from "./FleetProvider";

const TagsContext = createContext<{
  showAddTag: boolean;
  setShowAddTag: Dispatch<SetStateAction<boolean>>;
  fleetTagsList:
    | {
        id: number;
        key: string;
        count: number;
      }[]
    | undefined;
}>({
  showAddTag: false,
  setShowAddTag: null as unknown as Dispatch<SetStateAction<boolean>>,
  fleetTagsList: [],
});

export function useTags() {
  return useContext(TagsContext);
}

export function TagsProvider({ children }: { children: ReactNode }) {
  const [showAddTag, setShowAddTag] = useState(false);
  const { currentFleetIds } = useFleets();

  const { data: tagsFleetVehicles } = useGetFleetVehiclesTagsQuery({
    skip: !currentFleetIds,
    variables: {
      fleetIds: currentFleetIds,
    },
  });

  const fleetTagsList = tagsFleetVehicles?.tagsFleetVehicles.map((tag) => {
    assert.ok(
      tag.id !== null && tag.id !== undefined,
      "tag.id is null or undefined"
    );
    assert.ok(
      tag.key !== null && tag.key !== undefined,
      "tag.key is null or undefined"
    );

    return {
      id: tag.id,
      key: tag.key,
      count: tag.count || 0,
    };
  });

  const value = {
    showAddTag,
    setShowAddTag,
    fleetTagsList,
  };
  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
}
