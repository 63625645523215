import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import * as yup from "yup";
import { InferType } from "yup";

import { uploadFile } from "shared/lib/firebaseStorage";

import {
  FromWebsite,
  Reason,
} from "../../../../../backend/common/customerSupport.types";
import { askQuestion } from "../../generated/clients/customerSupport.client";
import ImageBox from "../ImageBox";
import ImageDropzone from "../ImageDropzone";
import ControlledTextField from "../controlledFields/ControlledTextField";

import FormModal from "./FormModal";

const schema = yup.object({
  reason: yup.mixed<Reason>().oneOf(Object.values(Reason)).required(),
  description: yup.string().required("Please enter a description"),
  images: yup.array().of(yup.mixed<File>().required()).required(),
});
type FormValues = InferType<typeof schema>;

export default function AskQuestion({
  onClose,
  from,
}: Readonly<{
  onClose: () => void;
  from: FromWebsite;
}>) {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      reason: Reason.AskQuestion,
      description: "",
      images: [],
    },
    resolver: yupResolver(schema),
  });

  const images = useWatch({
    control,
    name: "images",
  });

  const uploadImages = async (images: File[]) => {
    const imageArray = images || [];

    const uploadedImages = await Promise.all(
      imageArray.map(async (image) => {
        if (!image) return null;
        const result = await uploadFile(image);
        if (result.url) {
          return result.url;
        } else {
          toast.error("Failed to upload image");
          return null; // using null to signify a failed upload
        }
      })
    );

    // Filter out nulls if there were any failed uploads
    return uploadedImages.flatMap((img) => (img !== null ? [img] : []));
  };

  const onSubmit = async (data: FormValues) => {
    const { description, reason, images } = data;
    const uploadedImages = await uploadImages(images);
    const body = {
      from,
      description,
      reason,
      source: window.location.href,
      images: uploadedImages,
    };
    const response = await askQuestion({ body });
    if (response.error) {
      setError("root", {
        message: `Could not send feedback. ${response.error.message}`,
      });
      return;
    }
    toast.success("Your feedback has been sent!");
    onClose();
  };

  return (
    <FormModal
      open={true}
      title={"Get Help"}
      subtitle=""
      onSubmit={handleSubmit(onSubmit)}
      icon={<QuestionAnswerOutlinedIcon />}
      handleClose={onClose}
      loading={isSubmitting}
      isValid={true}
      submitButtonText={"Submit"}
      globalErrorMessage={errors.root?.message}
      content={
        <Stack spacing={1} mt={1}>
          <FormControl fullWidth>
            <InputLabel id="reason-select-label">Reason</InputLabel>
            <Controller
              name="reason"
              control={control}
              render={({ field }) => (
                <Select
                  labelId="reason-select-label"
                  id="reason-select"
                  label="Reason"
                  {...field}
                >
                  <MenuItem value={Reason.AskQuestion}>Ask a question</MenuItem>
                  <MenuItem value={Reason.ReportBug}>Report a bug</MenuItem>
                  <MenuItem value={Reason.SuggestImprovement}>
                    Suggest an improvement
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <ControlledTextField
            required
            disabled={isSubmitting}
            control={control}
            placeholder="Tell us what you expected to find"
            name="description"
            label="Description"
          />
          <Typography>
            Images <span style={{ color: "grey" }}>{" (optional)"}</span>
          </Typography>
          <ImageDropzone<FormValues>
            imagesVariableName={"images"}
            images={images}
            control={control}
          />
          {images.map((image: File) => (
            <ImageBox
              key={image.name}
              image={image}
              onDelete={() => {
                setValue(
                  "images",
                  images.filter((file) => file !== image)
                );
              }}
            />
          ))}
        </Stack>
      }
    />
  );
}
